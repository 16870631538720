import { ReactThreeFiber } from "@react-three/fiber";
import { shaderMaterial } from "./shader-material";
import { Texture } from "three";

/**
 * Simple Vertex shader:
 * Passes world position and texture coordinates
 */
const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv         = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

/**
 * Fragment shader for out of radius texturing
 */
const fragmentShader = `
uniform sampler2D map;
varying vec2 vUv;

void main() {
  vec2 uvNormalized = vUv * 2.0 - 1.0;      // Change UV range from [0, 1] to [-1, 1]
  float radius      = length(uvNormalized);

  if(radius < 0.9) {
    discard;
  }
  else{
    gl_FragColor.rgba = texture2D(map, vUv).rgba;
  }
}`;

export const RadiusTexMaterial = shaderMaterial({ map: new Texture() }, vertexShader, fragmentShader);

declare global {
  namespace JSX {
    interface IntrinsicElements {
      radiusTexMaterial: ReactThreeFiber.Node<
        typeof RadiusTexMaterial & JSX.IntrinsicElements["shaderMaterial"],
        typeof RadiusTexMaterial
      >;
    }
  }
}
