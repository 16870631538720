import "./css/field-of-view-renderer-webgl.scss";
import { Canvas } from "@react-three/fiber";
import { SVScenario, SingleVisionScene } from "./webGL/single-vision-scene";
import { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { SingleVisionProductLensType } from "../field-of-view/model";
import { FovFrameShade, FovRenderingMode } from "./field-of-view-renderer-shared";
import { ReactComponent as CompareIcon } from "./scenarios/single-vision/slider.svg";

/**
 * Callback for the suspense function, displayed while WebGL is being loaded
 * @returns
 */
function Loading() {
  return <h2>...</h2>;
}

/**
 * Properties definition for this component
 */
interface FieldOfViewRendererWebGLProps {
  topMargin?: boolean;
  scenario: SVScenario;
  circleParams1: number[];
  circleParams2: number[];
  plusMode: boolean;
  distortionStrength: number;
  productLensType1: SingleVisionProductLensType;
  productLensType2: SingleVisionProductLensType;
  renderingMode: FovRenderingMode;
  frameShade: FovFrameShade;
}

/**
 * Renders a WebGL scene representing a field-of-view
 * @param props Properties for this component
 * @returns Field of view rendering
 *
 * Comparison Slider reference: https://www.letsbuildui.dev/articles/how-to-build-an-image-comparison-slider/
 */
export function FieldOfViewRendererWebGL(props: FieldOfViewRendererWebGLProps) {
  const [isResizing, setIsResizing] = useState(false);
  const [comparisonMode, setComparisonMode] = useState(false);
  const comparisonCanvasRef = useRef<HTMLCanvasElement>(null!);
  const handleRef = useRef<HTMLDivElement>(null!);

  /**
   * Updates comparison slider position and comparison view clip path depending on current pointer position during dragging
   */
  const setPositioning = useCallback(
    (x: any) => {
      const { left, width } = comparisonCanvasRef.current.getBoundingClientRect();
      const handleWidth = handleRef.current.offsetWidth;
      const rangeFactor = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? 0.25 : 0.07;

      if (x >= left + (rangeFactor * width) / 2 && x <= (1 - rangeFactor / 2) * width + left - handleWidth) {
        handleRef.current.style.left = `${((x - left) / width) * 100}%`;
        comparisonCanvasRef.current.style.clipPath = `inset(0 0% 0 ${((x - left) / width) * 100}%)`;
      }
    },
    [props.renderingMode]
  );

  /**
   * Callback used when the comparison slider is being moved (Mouse)
   */
  const handleResize = useCallback(
    (e: { clientX: any }) => {
      setPositioning(e.clientX);
    },
    [setPositioning]
  );

  /**
   * Callback used when the comparison slider is being moved (Touches)
   */
  const handleResizeTouch = useCallback(
    (e: { touches: any }) => {
      setPositioning(e.touches[0].clientX);
    },
    [setPositioning]
  );

  /**
   * Callback used when the comparison slider has finished moving
   */
  const handleResizeEnd = useCallback(() => {
    setIsResizing(false);
    window.removeEventListener("mousemove", handleResize);
    window.removeEventListener("touchmove", handleResizeTouch);
    window.removeEventListener("mouseup", handleResizeEnd);
    window.removeEventListener("touchend", handleResizeEnd);
  }, [handleResize, handleResizeTouch]);

  /**
   * Update effect: add / removes event listeners when the user moves the comparison slider
   */
  useEffect(() => {
    if (isResizing) {
      window.addEventListener("mousemove", handleResize);
      window.addEventListener("touchmove", handleResizeTouch);
      window.addEventListener("mouseup", handleResizeEnd);
      window.addEventListener("touchend", handleResizeEnd);
    }

    return () => {
      window.removeEventListener("mousemove", handleResize);
      window.removeEventListener("touchmove", handleResizeTouch);
      window.removeEventListener("mouseup", handleResizeEnd);
      window.removeEventListener("touchend", handleResizeEnd);
    };
  }, [isResizing, handleResize, handleResizeEnd, handleResizeTouch]);

  /**
   * Update effect: toggles between standard and comparison mode
   */
  useEffect(() => {
    if (props.productLensType2 !== SingleVisionProductLensType.NONE && comparisonMode === false) {
      setComparisonMode(true);
    } else if (props.productLensType2 === SingleVisionProductLensType.NONE && comparisonMode === true) {
      setComparisonMode(false);
    }
  }, [props.productLensType2, comparisonMode]);

  /**
   * Init effect: set initial comparison handle position and corresponding clip-path to 50%
   */
  useEffect(() => {
    handleRef.current.style.left = `50%`;
    comparisonCanvasRef.current.style.clipPath = `inset(0 0% 0 50%)`;
  }, []);

  /**
   * Rendering
   */
  return (
    <>
      <Suspense fallback={<Loading />}>
        <div className={`webgl-wrapper${props.topMargin ? " spacing-top" : ""}`}>
          <div className="canvas-container">
            <Canvas
              id="webGlCanvas"
              orthographic
              frameloop="demand"
              resize={{
                scroll: true,
                debounce: { scroll: 0, resize: 0 },
                offsetSize: true,
              }}
              linear
            >
              <SingleVisionScene
                scenario={props.scenario}
                circleParams={props.circleParams1}
                plusMode={props.plusMode}
                distortionStrength={props.distortionStrength}
                productLensType={props.productLensType1}
                renderingMode={props.renderingMode}
                frameShade={props.frameShade}
                fovLineColor="#0ac8be"
              />
            </Canvas>
          </div>
          <div className="canvas-container-comparison" hidden={!comparisonMode}>
            <div
              ref={handleRef}
              className="handle"
              onMouseDown={() => setIsResizing(true)}
              onTouchStart={() => setIsResizing(true)}
              hidden={!comparisonMode}
            >
              <CompareIcon />
            </div>
            <Canvas
              id="webGlCanvas"
              orthographic
              frameloop="demand"
              resize={{
                scroll: true,
                debounce: { scroll: 0, resize: 0 },
                offsetSize: true,
              }}
              linear
              ref={comparisonCanvasRef}
            >
              <SingleVisionScene
                scenario={props.scenario}
                circleParams={props.circleParams2}
                plusMode={props.plusMode}
                distortionStrength={props.distortionStrength}
                productLensType={props.productLensType2}
                renderingMode={props.renderingMode}
                frameShade={props.frameShade}
                fovLineColor="#000000"
              />
            </Canvas>
          </div>
        </div>
      </Suspense>
    </>
  );
}
