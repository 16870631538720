import { ReactThreeFiber } from '@react-three/fiber';
import { Color, Vector2 } from 'three';

import { shaderMaterial } from './shader-material';

/**
 * Simple Vertex shader:
 * Passes world position
 */
const vertexShader = `
  void main() {
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

/**
 * Fragment shader for radial dash
 */
const fragmentShader = `
uniform vec3 color;
uniform vec2 screenSize;
uniform float dashCount;
uniform float yOffset;

void main() {
  vec2 uv = vec2(gl_FragCoord.x / screenSize.x, (gl_FragCoord.y - yOffset) / screenSize.y);
  vec2 uvNormalized = uv * 2.0 - 1.0;  // Change UV range from [0, 1] to [-1, 1]

  float angleStep = radians(360.0) / dashCount;
  float angle     = atan(uvNormalized.y, uvNormalized.x);
  float sectorNb  = floor(angle / angleStep);

  if(mod(sectorNb, 2.0) == 0.0) {
    discard;
  }
  else {
    gl_FragColor.rgb = color.rgb;
    gl_FragColor.a = 1.0;
 }  
}`;

export const RadialDashMaterial = shaderMaterial(
  {
    color: new Color(),
    screenSize: new Vector2(),
    dashCount: 0,
    yOffset: 0,
  },
  vertexShader,
  fragmentShader
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      radialDashMaterial: ReactThreeFiber.Node<
        typeof RadialDashMaterial & JSX.IntrinsicElements['shaderMaterial'],
        typeof RadialDashMaterial
      >;
    }
  }
}
