import {SingleVisionProductLensType } from "../field-of-view/model";

/**
 * Standard params for SV
 */
export const standardPT = 8;
export const standardCVD = 13;
export const standardFFA = 5;

/**
 * Interface for calculation SV Ellipses
 */
export interface SVParameters {
    pantoscopicTilt: number;
    faceFormAngle: number;
    corneaVertexDistance: number;
    productLensType: SingleVisionProductLensType;
}

/**
 * Circle for the best products with standard parameters, as [yOffset, radius]
 * => Fills 90% of the viewport
 */
const bestSVCircle: number[] =[0, 0.9]

/**
 * Calculates single vision circle depending on Product lens type and individual parameters
 * @param params Input parameters
 * @returns Array containing [circleYOffset, circleRadius]
 */
export function calculateSVCircle(params: SVParameters) {
  // Initialize result with best possible circle
  const result = [bestSVCircle[0], bestSVCircle[1]]

  // PT: Standard 8, range [-5; 20]
  const ptDeviation = (Math.abs(params.pantoscopicTilt - standardPT) * 2) / 25;

  // FFA: Standard 10, range [-5; 15]
  const ffaDeviation = (Math.abs(params.faceFormAngle - standardFFA) * 2) / 20;

  // CVD: Standard 13, range [5; 30]
  const cvdDeviation = (Math.abs(params.corneaVertexDistance - standardCVD) * 2) / 25;

  // Total deviation: effect from FFA > effect from PT > effect from CVD
  const totalDeviation = (Math.PI / 2) * ((3 * ffaDeviation + 2 * ptDeviation + cvdDeviation) / 6)

  // Set basis ellipse depending on product lens type
  switch(params.productLensType) {
    case SingleVisionProductLensType.MULTIGRESSIV_DNEYE_MONO:
    case SingleVisionProductLensType.MULTIGRESSIV_AEYE_MONO:
      // Best circle
      // PT + FFA + CVD: changes circle radius              
      result[1] *= (1 - Math.sin(totalDeviation) / 6)
      break;

    case SingleVisionProductLensType.COSMOLIT_DNEYE_MONO:
    case SingleVisionProductLensType.COSMOLIT_AEYE_MONO:
      // 90% of best circle
      // PT + FFA + CVD: changes circle radius
      result[1] *= 0.9                
      result[1] *= (1 - Math.sin(totalDeviation) / 5.5)
      break;

    case SingleVisionProductLensType.COSMOLIT:
    case SingleVisionProductLensType.PUNKTULIT_AS:
      // 80% of best circle
      // PT + FFA + CVD: changes circle radius
      result[1] *= 0.8                 
      result[1] *= (1 - Math.sin(totalDeviation) / 5)
      break;

    case SingleVisionProductLensType.PERFALIT:
    case SingleVisionProductLensType.PUNKTULIT:
      // 75% of best circle
      // PT + FFA + CVD: changes circle radius
      result[1] *= 0.75              
      result[1] *= (1 - Math.sin(totalDeviation) / 4)
      break;

    default:
      // Best circle
      // PT + FFA + CVD: no effect
      break;
  }

 // result[0] /= 2.0;
  return result;
}

/**
 * Returns the strength for the distortion filter for a given lens product
 * @param product The lens-product for which to retrieve the distortion strength
 * @returns The distortion strength
 */
export function getDistortionStrength(product: SingleVisionProductLensType): number {
    switch (product) {
        case SingleVisionProductLensType.IMPRESSION_DNEYE_MONO:
        case SingleVisionProductLensType.IMPRESSION_AEYE_MONO:
          return 6.0;
    
        case SingleVisionProductLensType.MULTIGRESSIV_DNEYE_MONO:
        case SingleVisionProductLensType.MULTIGRESSIV_AEYE_MONO:
          return 2;
    
        case SingleVisionProductLensType.COSMOLIT_DNEYE_MONO:
        case SingleVisionProductLensType.COSMOLIT_AEYE_MONO:
          return 1.5;
    
        case SingleVisionProductLensType.COSMOLIT:
        case SingleVisionProductLensType.PERFALIT:
        case SingleVisionProductLensType.PUNKTULIT_AS:
        case SingleVisionProductLensType.PUNKTULIT:
          return 1;
    
        default:
          return 1;
      }
}