import "./css/field-of-view-renderer-shared.scss";
import "./css/field-of-view-renderer-progressive.scss";

import { useEffect, useRef, useState } from "react";

import {
  FieldOfViewCoordinate,
  LensType,
  NearVisionProductLensType,
  ProgressiveProductLensType,
  SingleVisionProductLensType,
  SportProductLensType,
} from "../field-of-view/model";
import ScenarioNearViewAEye from "./scenarios/near-vision/nv-aeye.jpg";
import ScenarioNearViewAEyeBlur3 from "./scenarios/near-vision/nv-aeye-blur3.jpg";
import ScenarioNearViewAEyeBlur5 from "./scenarios/near-vision/nv-aeye-blur5.jpg";
import ScenarioNearViewAEyeBlur5White from "./scenarios/near-vision/nv-aeye-blur5-white.jpg";
import ScenarioNearViewAEyeBlur10 from "./scenarios/near-vision/nv-aeye-blur10.jpg";
import ScenarioNearViewAEyeBlur20 from "./scenarios/near-vision/nv-aeye-blur20.jpg";
import ScenarioNearViewBasic from "./scenarios/near-vision/nv-basic.jpg";
import ScenarioNearViewBasicBlur3 from "./scenarios/near-vision/nv-basic-blur3.jpg";
import ScenarioNearViewBasicBlur5 from "./scenarios/near-vision/nv-basic-blur5.jpg";
import ScenarioNearViewBasicBlur5White from "./scenarios/near-vision/nv-basic-blur5-white.jpg";
import ScenarioNearViewBasicBlur10 from "./scenarios/near-vision/nv-basic-blur10.jpg";
import ScenarioNearViewBasicBlur20 from "./scenarios/near-vision/nv-basic-blur20.jpg";
import ScenarioNearViewDepth0 from "./scenarios/near-vision/nv-depth0.svg";
import ScenarioNearViewDepth1 from "./scenarios/near-vision/nv-depth1.svg";
import ScenarioNearViewDepth2 from "./scenarios/near-vision/nv-depth2.svg";
import ScenarioNearViewDepth3 from "./scenarios/near-vision/nv-depth3.svg";
import ScenarioNearViewDepth4 from "./scenarios/near-vision/nv-depth4.svg";
import ScenarioNearViewDepth5 from "./scenarios/near-vision/nv-depth5.svg";
import ScenarioNearViewDepth6 from "./scenarios/near-vision/nv-depth6.svg";
import ScenarioNearViewDepth7 from "./scenarios/near-vision/nv-depth7.svg";
import ScenarioNearViewDNEye from "./scenarios/near-vision/nv-dneye.jpg";
import ScenarioNearViewDNEyeBlur3 from "./scenarios/near-vision/nv-dneye-blur3.jpg";
import ScenarioNearViewDNEyeBlur5 from "./scenarios/near-vision/nv-dneye-blur5.jpg";
import ScenarioNearViewDNEyeBlur5White from "./scenarios/near-vision/nv-dneye-blur5-white.jpg";
import ScenarioNearViewDNEyeBlur10 from "./scenarios/near-vision/nv-dneye-blur10.jpg";
import ScenarioNearViewDNEyeBlur20 from "./scenarios/near-vision/nv-dneye-blur20.jpg";
import ScenarioNearViewNetline from "./scenarios/near-vision/nv-netline.jpg";
import ScenarioNearViewNetlineBlur3 from "./scenarios/near-vision/nv-netline-blur3.jpg";
import ScenarioNearViewNetlineBlur5 from "./scenarios/near-vision/nv-netline-blur5.jpg";
import ScenarioNearViewNetlineBlur5White from "./scenarios/near-vision/nv-netline-blur5-white.jpg";
import ScenarioNearViewNetlineBlur10 from "./scenarios/near-vision/nv-netline-blur10.jpg";
import ScenarioNearViewNetlineBlur20 from "./scenarios/near-vision/nv-netline-blur20.jpg";
import ScenarioProgressiveAEye from "./scenarios/progressive/prog-aeye.jpg";
import ScenarioProgressiveAEyeBlur5 from "./scenarios/progressive/prog-aeye-blur5.jpg";
import ScenarioProgressiveAEyeBlur5White from "./scenarios/progressive/prog-aeye-blur5-white.jpg";
import ScenarioProgressiveBasic from "./scenarios/progressive/prog-basic.jpg";
import ScenarioProgressiveBasicBlur5 from "./scenarios/progressive/prog-basic-blur5.jpg";
import ScenarioProgressiveBasicBlur5White from "./scenarios/progressive/prog-basic-blur5-white.jpg";
import ScenarioProgressiveDNEye from "./scenarios/progressive/prog-dneye.jpg";
import ScenarioProgressiveDNEyeBlur5 from "./scenarios/progressive/prog-dneye-blur5.jpg";
import ScenarioProgressiveDNEyeBlur5White from "./scenarios/progressive/prog-dneye-blur5-white.jpg";
import ScenarioProgressiveNetline from "./scenarios/progressive/prog-netline.jpg";
import ScenarioProgressiveNetlineBlur5 from "./scenarios/progressive/prog-netline-blur5.jpg";
import ScenarioProgressiveNetlineBlur5White from "./scenarios/progressive/prog-netline-blur5-white.jpg";
import ScenarioSportDNEye from "./scenarios/progressive/sport/sport-dneye.jpg";
import ScenarioSportDNEyeBlur5 from "./scenarios/progressive/sport/sport-dneye-blur5.jpg";
import ScenarioSportDNEyeBlur5White from "./scenarios/progressive/sport/sport-dneye-blur5-white.jpg";
import ScenarioSunDNEye from "./scenarios/progressive/sun/sun-dneye.jpg";
import ScenarioSunDNEyeBlur5 from "./scenarios/progressive/sun/sun-dneye-blur5.jpg";
import ScenarioSunDNEyeBlur5White from "./scenarios/progressive/sun/sun-dneye-blur5-white.jpg";
import ScenarioSunAEye from "./scenarios/progressive/sun/sun-aeye.jpg";
import ScenarioSunAEyeBlur5 from "./scenarios/progressive/sun/sun-aeye-blur5.jpg";
import ScenarioSunAEyeBlur5White from "./scenarios/progressive/sun/sun-aeye-blur5-white.jpg";
import ScenarioSunBasic from "./scenarios/progressive/sun/sun-basic.jpg";
import ScenarioSunBasicBlur5 from "./scenarios/progressive/sun/sun-basic-blur5.jpg";
import ScenarioSunBasicBlur5White from "./scenarios/progressive/sun/sun-basic-blur5-white.jpg";
import ScenarioSunNetline from "./scenarios/progressive/sun/sun-netline.jpg";
import ScenarioSunNetlineBlur5 from "./scenarios/progressive/sun/sun-netline-blur5.jpg";
import ScenarioSunNetlineBlur5White from "./scenarios/progressive/sun/sun-netline-blur5-white.jpg";

import {
  FovFrameShade,
  FovRenderingMode,
  frameShapePath,
  frameShapeTransformStr,
  lensShapeInFrameTransformStr,
  lensShapeInFrameXOffset,
  lensShapeInFrameYOffset,
  lensShapePath,
  lensShapeScaleStr,
  svgViewBox,
  svgViewBoxStr,
} from "./field-of-view-renderer-shared";

/**
 * Display modes supported by the field-of-view renderer
 */
export enum FovDisplayMode {
  STANDARD = 0,
  TUNER_PROGRESSIVE = 1,
  TUNER_NEARVISION = 2,
}

/**
 * Rendering aspect of the FOV zones
 */
export enum FovZonesMode {
  STANDARD = 0,
  WHITE = 1,
}

/**
 * Proximity (= 1 / Distance in meters) of the depth layers for Near Vision
 */
export const depthLayersProximity = [
  1 / 0.2, // Layer 0 - 20cm (Desk front side)
  1 / 0.35, // Layer 1 - 35cm (Desk from front side to keyboard)
  1 / 0.5, // Layer 2 - 50cm (Keyboard, Coffee, Thermos)
  1 / 0.74, // Layer 3 - 74cm (Screen, scissors, lamp)
  1 / 1.3, // Layer 4 - 130cm (Desk on the right)
  1 / 1.7, // Layer 5 - 170cm (Sitzkissen on the left)
  1 / 2.7, // Layer 6 - 270cm (Black board)
  1 / 4.4, // Layer 7 - 440cm (Clock & back wall)
];

/**
 * Threshold values used to determine the blur level of the various depth layers
 * when Depth-of-Field is applied
 */
const depthPerformanceThresholds = [
  0.81, // 81% for Blur0
  0.572, // 64% for Blur3
  0.477, // 51% for Blur10
];

/**
 * Returns an array of image names based on the received lensType and productLensType
 * @param lensType Input lensType
 * @param productLensType Input product lensType
 * @returns Arry containing corresponding scenario image with increasing blur levels: [Blur0, Blur3, Blur10, Blur20]
 */
const mapProductLensTypeToScenario = (
  lensType: LensType,
  productLensType:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType
    | undefined,
  fovZonesMode: FovZonesMode,
  sunMode: boolean
): string[] => {
  let scenarioImage = "";
  let scenarioImageBlur3 = "";
  let scenarioImageBlur10 = "";
  let scenarioImageBlur20 = "";
  let scenarioImageBlur5 = "";
  switch (lensType) {
    case LensType.PROGRESSIVE:
      switch (productLensType) {
        case ProgressiveProductLensType.IMPRESSION_AEYE:
        case ProgressiveProductLensType.MULTIGRESSIV_AEYE:
        case ProgressiveProductLensType.PROGRESSIV_AEYE:
          scenarioImage = sunMode ? ScenarioSunAEye : ScenarioProgressiveAEye;
          if (sunMode) {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD ? ScenarioSunAEyeBlur5 : ScenarioSunAEyeBlur5White;
          } else {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD ? ScenarioProgressiveAEyeBlur5 : ScenarioProgressiveAEyeBlur5White;
          }
          break;

        case ProgressiveProductLensType.PROGRESSIV_LIFE:
          scenarioImage = sunMode ? ScenarioSunBasic : ScenarioProgressiveBasic;
          if (sunMode) {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD ? ScenarioSunBasicBlur5 : ScenarioSunBasicBlur5White;
          } else {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD
                ? ScenarioProgressiveBasicBlur5
                : ScenarioProgressiveBasicBlur5White;
          }
          break;

        case ProgressiveProductLensType.PROGRESSIV_FREE_PRO:
        case ProgressiveProductLensType.PROGRESSIV_FREE:
        case ProgressiveProductLensType.PROGRESSIV_SI:
          scenarioImage = sunMode ? ScenarioSunNetline : ScenarioProgressiveNetline;
          if (sunMode) {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD ? ScenarioSunNetlineBlur5 : ScenarioSunNetlineBlur5White;
          } else {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD
                ? ScenarioProgressiveNetlineBlur5
                : ScenarioProgressiveNetlineBlur5White;
          }
          break;

        default:
          scenarioImage = sunMode ? ScenarioSunDNEye : ScenarioProgressiveDNEye;
          if (sunMode) {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD ? ScenarioSunDNEyeBlur5 : ScenarioSunDNEyeBlur5White;
          } else {
            scenarioImageBlur5 =
              fovZonesMode === FovZonesMode.STANDARD
                ? ScenarioProgressiveDNEyeBlur5
                : ScenarioProgressiveDNEyeBlur5White;
          }
          break;
      }
      break;

    case LensType.NEAR_VISION:
      switch (productLensType) {
        case NearVisionProductLensType.IMPRESSION_AEYE_ERGO:
        case NearVisionProductLensType.MULTIGRESSIV_AEYE_ERGO:
        case NearVisionProductLensType.PROGRESSIV_AEYE_ERGO:
          scenarioImage = ScenarioNearViewAEye;
          scenarioImageBlur3 = ScenarioNearViewAEyeBlur3;
          scenarioImageBlur10 = ScenarioNearViewAEyeBlur10;
          scenarioImageBlur20 = ScenarioNearViewAEyeBlur20;
          scenarioImageBlur5 =
            fovZonesMode === FovZonesMode.STANDARD ? ScenarioNearViewAEyeBlur5 : ScenarioNearViewAEyeBlur5White;
          break;

        case NearVisionProductLensType.PROGRESSIV_ERGO:
          scenarioImage = ScenarioNearViewBasic;
          scenarioImageBlur3 = ScenarioNearViewBasicBlur3;
          scenarioImageBlur10 = ScenarioNearViewBasicBlur10;
          scenarioImageBlur20 = ScenarioNearViewBasicBlur20;
          scenarioImageBlur5 =
            fovZonesMode === FovZonesMode.STANDARD ? ScenarioNearViewBasicBlur5 : ScenarioNearViewBasicBlur5White;
          break;

        case NearVisionProductLensType.NETLINE_ROOM:
        case NearVisionProductLensType.NETLINE_WORK1:
        case NearVisionProductLensType.NETLINE_WORK2:
        case NearVisionProductLensType.NETLINE_40:
          scenarioImage = ScenarioNearViewNetline;
          scenarioImageBlur3 = ScenarioNearViewNetlineBlur3;
          scenarioImageBlur10 = ScenarioNearViewNetlineBlur10;
          scenarioImageBlur20 = ScenarioNearViewNetlineBlur20;
          scenarioImageBlur5 =
            fovZonesMode === FovZonesMode.STANDARD ? ScenarioNearViewNetlineBlur5 : ScenarioNearViewNetlineBlur5White;
          break;

        default:
          scenarioImage = ScenarioNearViewDNEye;
          scenarioImageBlur3 = ScenarioNearViewDNEyeBlur3;
          scenarioImageBlur10 = ScenarioNearViewDNEyeBlur10;
          scenarioImageBlur20 = ScenarioNearViewDNEyeBlur20;
          scenarioImageBlur5 =
            fovZonesMode === FovZonesMode.STANDARD ? ScenarioNearViewDNEyeBlur5 : ScenarioNearViewDNEyeBlur5White;
          break;
      }
      break;

    case LensType.SPORT:
      scenarioImage = ScenarioSportDNEye;
      scenarioImageBlur5 =
        fovZonesMode === FovZonesMode.STANDARD ? ScenarioSportDNEyeBlur5 : ScenarioSportDNEyeBlur5White;
      break;

    default:
      break;
  }

  return [scenarioImage, scenarioImageBlur3, scenarioImageBlur10, scenarioImageBlur20, scenarioImageBlur5];
};

/**
 * Properties definition for this component
 */
interface FieldOfViewRendererProgressiveProps {
  coordinates1: FieldOfViewCoordinate[];
  coordinates2: FieldOfViewCoordinate[];
  dofValues: number[];
  lensType: LensType;
  productLensType1?:
    | NearVisionProductLensType
    | ProgressiveProductLensType
    | SingleVisionProductLensType
    | SportProductLensType;
  displayMode: FovDisplayMode;
  renderingMode: FovRenderingMode;
  frameShade: FovFrameShade;
  dF: number;
  dN: number;
  topMargin?: boolean;
  fovZonesMode: FovZonesMode;
  sunMode: boolean;
  elementId: string;
}

export function FieldOfViewRendererProgressive(props: FieldOfViewRendererProgressiveProps) {
  const dFTitleTextRef = useRef<SVGTextElement>(null);
  const dFValTextRef = useRef<SVGTextElement>(null);
  const dNTitleTextRef = useRef<SVGTextElement>(null);
  const dNValTextRef = useRef<SVGTextElement>(null);

  const [fovContourRight1, setFOVContourRight1] = useState<string>("");
  const [fovContourLeft1, setFOVContourLeft1] = useState<string>("");
  const [fovLineRight1, setFOVLineRight1] = useState<string>("");
  const [fovLineLeft1, setFOVLineLeft1] = useState<string>("");
  const [fovContourRight2, setFOVContourRight2] = useState<string>("");
  const [fovContourLeft2, setFOVContourLeft2] = useState<string>("");
  const [fovLineRight2, setFOVLineRight2] = useState<string>("");
  const [fovLineLeft2, setFOVLineLeft2] = useState<string>("");
  const [drawFov1, setDrawFov1] = useState<string>("hidden");
  const [drawFov2, setDrawFov2] = useState<string>("hidden");
  const [centeringCrossCoords, setCenteringCrossCoords] = useState<number[]>([0, 0, 0, 0, 0, 0, 0, 0]);
  const [dFLineCoords, setDFLineCoords] = useState<number[]>([0, 0, 0, 0]);
  const [dNLineCoords, setDNLineCoords] = useState<number[]>([0, 0, 0, 0]);
  const [dFTitleIndicatorCoords, setDFTitleIndicatorCoords] = useState<number[]>([0, 0]);
  const [dFValIndicatorCoords, setDFValIndicatorCoords] = useState<number[]>([0, 0]);
  const [dNTitleIndicatorCoords, setDNTitleIndicatorCoords] = useState<number[]>([0, 0]);
  const [dNValIndicatorCoords, setDNValIndicatorCoords] = useState<number[]>([0, 0]);
  const [drawTunerIndicators, setDrawTunerIndicators] = useState<string>("hidden");
  const [dFTitleText, setDFTitleText] = useState<string>("DF");
  const [dFValText, setDFValText] = useState<string>("0 cm");
  const [dNTitleText] = useState<string>("DN");
  const [dNValText, setDNValText] = useState<string>("0 cm");
  const [dFTitleBackgroundRect, setDFTitleBackgroundRect] = useState<number[]>([0, 0, 0, 0]);
  const [dFValBackgroundRect, setDFValBackgroundRect] = useState<number[]>([0, 0, 0, 0]);
  const [dNTitleBackgroundRect, setDNTitleBackgroundRect] = useState<number[]>([0, 0, 0, 0]);
  const [dNValBackgroundRect, setDNValBackgroundRect] = useState<number[]>([0, 0, 0, 0]);
  // eslint-disable-next-line prefer-const
  let [blur0Visibility, setBlur0Visibility] = useState<string[]>([
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
  ]);
  // eslint-disable-next-line prefer-const
  let [blur3Visibility, setBlur3Visibility] = useState<string[]>([
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
  ]);
  // eslint-disable-next-line prefer-const
  let [blur10Visibility, setBlur10Visibility] = useState<string[]>([
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
  ]);
  // eslint-disable-next-line prefer-const
  let [blur20Visibility, setBlur20Visibility] = useState<string[]>([
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
    "hidden",
  ]);
  const [fovClipPath, setFovClipPath] = useState<string>(`url(#${props.elementId}-clipPath_fullRect)`);
  const [frameShapeVisibility, setFrameShapeVisibility] = useState<string>("hidden");
  const [lensShapeTransform, setLensShapeTransform] = useState<string>(lensShapeScaleStr);
  const [scenarioSize, setScenarioSize] = useState<string>("100%");
  const [scenarioOffset, setScenarioOffset] = useState<number[]>([0, 0]);
  const [frameColor, setFrameColor] = useState<string>("#000000");

  /**
   * Converts original FOV lines coordinates, which are calculated for a [-25; 25] coordinates system,
   * to our SVG viewBox coordinates system for rendering.
   * This function calculates final coordinates for the FOV lines and for corresponding polygons
   * @param fovID Use the coordinates to plot FOV1 or FOV2?
   * @param coordinates Original coordinates
   */
  function buildFOVContour(fovID: number, coordinates: FieldOfViewCoordinate[]) {
    const renderingModeScalingFactor = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? 0.8 : 1.0;
    const offsetX = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? lensShapeInFrameXOffset : 0;
    const offsetY = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? lensShapeInFrameYOffset : 0;

    // Use SVG element's viewbox size to rescale properly
    const scaleX = (renderingModeScalingFactor * svgViewBox[0]) / 50;
    const scaleY = (renderingModeScalingFactor * svgViewBox[1]) / 50;

    let newFOVLineRight = "";
    let newFOVLineLeft = "";
    let newFOVContourRight = "";
    let newFOVContourLeft = "";

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < coordinates.length; i++) {
      let nextX = (coordinates[i].x + 25) * scaleX + offsetX;
      const nextY = (coordinates[i].y * -1 + 25) * scaleY + offsetY;

      newFOVLineRight += `${nextX},${nextY} `;

      nextX = (coordinates[i].x * -1 + 25) * scaleX + offsetX;
      newFOVLineLeft += `${nextX},${nextY} `;
    }

    // Polygon contour: add extra points at the beginning & the end
    if (coordinates.length > 0) {
      const additionalFirstPointLeftX1 = offsetX;
      const additionalFirstPointLeftX2 = (coordinates[0].x * -1 + 25.1) * scaleX + offsetX;
      const additionalFirstPointLeftY = 50 * scaleY + offsetY;
      const additionalLastPointLeftX1 = (coordinates[coordinates.length - 1].x * -1 + 25) * scaleX + offsetX;

      newFOVContourLeft += `${additionalFirstPointLeftX1},${additionalFirstPointLeftY} ${additionalFirstPointLeftX2},${additionalFirstPointLeftY} ${newFOVLineLeft}${additionalLastPointLeftX1},0 0,0`;

      const additionalFirstPointRightX1 = (coordinates[0].x * -1 + 25 + 100) * scaleX + offsetX;
      const additionalFirstPointRightX2 = (coordinates[0].x * -1 + 25) * scaleX + offsetX;
      const additionalFirstPointRightY = 50 * scaleY + offsetY;
      const additionalLastPointRightX1 = (coordinates[coordinates.length - 1].x + 25) * scaleX + offsetX;
      const additionalLastPointRightX2 = (coordinates[coordinates.length - 1].x + 25 + 100) * scaleX + offsetX;
      newFOVContourRight += `${additionalFirstPointRightX1},${additionalFirstPointRightY} ${additionalFirstPointRightX2},${additionalFirstPointRightY} ${newFOVLineRight}${additionalLastPointRightX1},0 ${additionalLastPointRightX2},0 `;

      switch (fovID) {
        case 0:
          setFOVLineRight1(newFOVLineRight);
          setFOVLineLeft1(newFOVLineLeft);
          setFOVContourRight1(newFOVContourRight);
          setFOVContourLeft1(newFOVContourLeft);
          break;
        case 1:
          setFOVLineRight2(newFOVLineRight);
          setFOVLineLeft2(newFOVLineLeft);
          setFOVContourRight2(newFOVContourRight);
          setFOVContourLeft2(newFOVContourLeft);
          break;

        default:
          break;
      }
    }
  }

  /**
   * Update Effect - Set coordinates of centering cross lines
   */
  useEffect(() => {
    const renderingModeScalingFactor = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? 0.8 : 1.0;
    const offsetX = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? lensShapeInFrameXOffset : 0;
    const offsetY = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? lensShapeInFrameYOffset : 0;

    // Centering cross is at [0; 0] in a [-25; 25] coordinates system in Y and X
    // Use SVG element's viewbox size to rescale properly
    const scaleX = (renderingModeScalingFactor * svgViewBox[0]) / 50;
    const scaleY = (renderingModeScalingFactor * svgViewBox[1]) / 50;
    const ccCenter = [25 * scaleX + offsetX, 25 * scaleY + offsetY]; // Invert Y coordinate in SVG!
    setCenteringCrossCoords([
      ccCenter[0] - 2 * scaleX,
      ccCenter[1],
      ccCenter[0] + 2 * scaleX,
      ccCenter[1],
      ccCenter[0],
      ccCenter[1] - 2 * scaleY,
      ccCenter[0],
      ccCenter[1] + 2 * scaleY,
    ]);
  }, [props.renderingMode]);

  /**
   * Update Effect - Runs when the FOV coordinates have changed
   */
  useEffect(() => {
    if (props.coordinates1.length > 0) {
      buildFOVContour(0, props.coordinates1);
      setDrawFov1("visible");
    } else {
      setDrawFov1("hidden");
    }
    if (props.coordinates2.length > 0) {
      buildFOVContour(1, props.coordinates2);
      setDrawFov2("visible");
    } else {
      setDrawFov2("hidden");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.coordinates1, props.coordinates2, props.renderingMode]);

  /**
   * Update Effect - Runs when the DOF configuration has changed
   */
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    blur0Visibility = ["hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    blur3Visibility = ["hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    blur10Visibility = ["hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden"];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    blur20Visibility = ["hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden", "hidden"];

    for (let i = 0; i < 8; i += 1) {
      if (props.dofValues[i] >= depthPerformanceThresholds[0]) {
        blur0Visibility[i] = "visible";
      } else if (props.dofValues[i] >= depthPerformanceThresholds[1]) {
        blur3Visibility[i] = "visible";
      } else if (props.dofValues[i] >= depthPerformanceThresholds[2]) {
        blur10Visibility[i] = "visible";
      } else {
        blur20Visibility[i] = "visible";
      }
    }

    setBlur0Visibility(blur0Visibility);
    setBlur3Visibility(blur3Visibility);
    setBlur10Visibility(blur10Visibility);
    setBlur20Visibility(blur20Visibility);
  }, [props.dofValues]);

  /**
   * Update Effect - Runs when display mode or the DF / DN position has changed
   */
  useEffect(() => {
    const renderingModeScalingFactor = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? 0.8 : 1.0;
    const offsetY = props.renderingMode === FovRenderingMode.FRAME_SHAPE ? lensShapeInFrameYOffset : 0;
    setDrawTunerIndicators(props.displayMode === FovDisplayMode.STANDARD ? "hidden" : "visible");

    // Use SVG element's viewbox size to rescale properly
    const scaleX = (renderingModeScalingFactor * svgViewBox[0]) / 50;
    const scaleY = (renderingModeScalingFactor * svgViewBox[1]) / 50;
    setDFLineCoords([0, (-props.dF + 25) * scaleY + offsetY, svgViewBox[0], (-props.dF + 25) * scaleY + offsetY]);
    setDNLineCoords([0, (-props.dN + 25) * scaleY + offsetY, svgViewBox[0], (-props.dN + 25) * scaleY + offsetY]);

    const dFTmp = Math.round(props.dF * 10) / 10;
    if (props.displayMode === FovDisplayMode.TUNER_PROGRESSIVE) {
      setDFTitleText(`DF`);
    } else {
      setDFTitleText(`DM`);
    }
    setDFValText(`${dFTmp} mm`);
    setDFTitleIndicatorCoords([3 * scaleX, (-props.dF + 28) * scaleY + offsetY]);
    setDFValIndicatorCoords([(0.5 + 9) * scaleX, (-props.dF + 28) * scaleY + offsetY]);

    const dNTmp = Math.round(props.dN * 10) / 10;
    setDNValText(`${dNTmp} mm`);
    setDNTitleIndicatorCoords([3 * scaleX, (-props.dN + 23) * scaleY + offsetY]);
    setDNValIndicatorCoords([(0.5 + 9) * scaleX, (-props.dN + 23) * scaleY + offsetY]);
  }, [props.displayMode, props.renderingMode, props.dF, props.dN]);

  /**
   * Update Effect - Runs when the DF / DN texts have changed
   */
  useEffect(() => {
    const scaleX = svgViewBox[0] / 50;
    const scaleY = svgViewBox[1] / 50;

    const dFTitleTextObject = dFTitleTextRef.current;
    if (dFTitleTextObject !== null) {
      const bBox = dFTitleTextObject.getBBox();
      setDFTitleBackgroundRect([0.2 * scaleX, bBox.y - 0.2 * scaleY, 6 * scaleX, bBox.height + 0.4 * scaleY]);
    }

    const dFValTextObject = dFValTextRef.current;
    if (dFValTextObject !== null) {
      const bBox = dFValTextObject.getBBox();
      setDFValBackgroundRect([
        bBox.x - 1 * scaleX,
        bBox.y - 0.2 * scaleY,
        bBox.width + 2 * scaleX,
        bBox.height + 0.4 * scaleY,
      ]);
    }

    const dNTitleTextObject = dNTitleTextRef.current;
    if (dNTitleTextObject !== null) {
      const bBox = dNTitleTextObject.getBBox();
      setDNTitleBackgroundRect([0.2 * scaleX, bBox.y - 0.2 * scaleY, 6 * scaleX, bBox.height + 0.4 * scaleY]);
    }

    const dNValTextObject = dNValTextRef.current;
    if (dNValTextObject !== null) {
      const bBox = dNValTextObject.getBBox();
      setDNValBackgroundRect([
        bBox.x - 1 * scaleX,
        bBox.y - 0.2 * scaleY,
        bBox.width + 2 * scaleX,
        bBox.height + 0.4 * scaleY,
      ]);
    }
  }, [dFValText, dNValText]);

  /**
   * Update Effect - Only show design tuner boxes once their position has been initialized
   * Fixes CC-884
   */
  useEffect(() => {
    if (dFTitleBackgroundRect[0] === 0) {
      setDrawTunerIndicators("hidden");
    } else if (props.displayMode !== FovDisplayMode.STANDARD) {
      setDrawTunerIndicators("visible");
    }
  }, [dFTitleBackgroundRect, props.displayMode]);

  /**
   * Update Effect - Runs when the FOV rendering mode has changed
   */
  useEffect(() => {
    switch (props.renderingMode) {
      case FovRenderingMode.FRAME_SHAPE:
        setFovClipPath(`url(#${props.elementId}-clipPath_lensShape)`);
        setLensShapeTransform(lensShapeInFrameTransformStr);
        setScenarioSize("80%");
        setScenarioOffset([lensShapeInFrameXOffset, lensShapeInFrameYOffset]);
        setFrameShapeVisibility("visible");
        break;

      case FovRenderingMode.LENS_SHAPE:
        setFovClipPath(`url(#${props.elementId}-clipPath_lensShape)`);
        setLensShapeTransform(lensShapeScaleStr);
        setScenarioSize("100%");
        setScenarioOffset([0, 0]);
        setFrameShapeVisibility("hidden");
        break;

      default:
        setFovClipPath(`url(#${props.elementId}-clipPath_fullRect)`);
        setScenarioSize("100%");
        setScenarioOffset([0, 0]);
        setFrameShapeVisibility("hidden");
        break;
    }
  }, [props.elementId, props.renderingMode]);

  /**
   * Update Effect - Runs when the Frame shade
   */
  useEffect(() => {
    switch (props.frameShade) {
      case FovFrameShade.DARK:
        setFrameColor("#000000");
        break;

      default:
        setFrameColor("#FFFFFF");
        break;
    }
  }, [props.frameShade]);

  /**
   * Rendering
   */
  return (
    <>
      <div className={`svg-container${props.topMargin ? " spacing-top" : ""}`}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          className="scenario-svg"
          id={`${props.elementId}-svg-element`}
          shapeRendering="optimizeQuality"
          imageRendering="optimizeSpeed"
          viewBox={svgViewBoxStr}
          preserveAspectRatio="xMidYMid meet"
        >
          <defs>
            <mask id={`${props.elementId}-fov-mask`} visibility={drawFov1}>
              <polygon points={fovContourLeft1} fill="#FFFFFF" />
              <polygon points={fovContourRight1} fill="#FFFFFF" />
            </mask>
            <mask id={`${props.elementId}-fov-mask2`} visibility={drawFov2}>
              <polygon points={fovContourLeft2} fill="#FFFFFF" />
              <polygon points={fovContourRight2} fill="#FFFFFF" />
            </mask>
            <mask id={`${props.elementId}-dof-mask-blur0`}>
              <image
                xlinkHref={ScenarioNearViewDepth0}
                visibility={blur0Visibility[0]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth1}
                visibility={blur0Visibility[1]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth2}
                visibility={blur0Visibility[2]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth3}
                visibility={blur0Visibility[3]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth4}
                visibility={blur0Visibility[4]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth5}
                visibility={blur0Visibility[5]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth6}
                visibility={blur0Visibility[6]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth7}
                visibility={blur0Visibility[7]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
            </mask>
            <mask id={`${props.elementId}-dof-mask-blur3`}>
              <image
                xlinkHref={ScenarioNearViewDepth0}
                visibility={blur3Visibility[0]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth1}
                visibility={blur3Visibility[1]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth2}
                visibility={blur3Visibility[2]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth3}
                visibility={blur3Visibility[3]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth4}
                visibility={blur3Visibility[4]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth5}
                visibility={blur3Visibility[5]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth6}
                visibility={blur3Visibility[6]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth7}
                visibility={blur3Visibility[7]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
            </mask>
            <mask id={`${props.elementId}-dof-mask-blur10`}>
              <image
                xlinkHref={ScenarioNearViewDepth0}
                visibility={blur10Visibility[0]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth1}
                visibility={blur10Visibility[1]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth2}
                visibility={blur10Visibility[2]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth3}
                visibility={blur10Visibility[3]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth4}
                visibility={blur10Visibility[4]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth5}
                visibility={blur10Visibility[5]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth6}
                visibility={blur10Visibility[6]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth7}
                visibility={blur10Visibility[7]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
            </mask>
            <mask id={`${props.elementId}-dof-mask-blur20`}>
              <image
                xlinkHref={ScenarioNearViewDepth0}
                visibility={blur20Visibility[0]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth1}
                visibility={blur20Visibility[1]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth2}
                visibility={blur20Visibility[2]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth3}
                visibility={blur20Visibility[3]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth4}
                visibility={blur20Visibility[4]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth5}
                visibility={blur20Visibility[5]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth6}
                visibility={blur20Visibility[6]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
              <image
                xlinkHref={ScenarioNearViewDepth7}
                visibility={blur20Visibility[7]}
                x={scenarioOffset[0]}
                y={scenarioOffset[1]}
                width={scenarioSize}
                height={scenarioSize}
              />
            </mask>
            <clipPath id={`${props.elementId}-clipPath_lensShape`} transform={lensShapeTransform}>
              <path d={lensShapePath} />
            </clipPath>
            <clipPath id={`${props.elementId}-clipPath_fullRect`}>
              <rect width={svgViewBox[0]} height={svgViewBox[1]} />
            </clipPath>
          </defs>
          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[3]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-dof-mask-blur20)`}
            clipPath={fovClipPath}
          />
          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[2]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-dof-mask-blur10)`}
            clipPath={fovClipPath}
          />
          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[1]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-dof-mask-blur3)`}
            clipPath={fovClipPath}
          />
          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[0]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-dof-mask-blur0)`}
            clipPath={fovClipPath}
          />

          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[4]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-fov-mask)`}
            clipPath={fovClipPath}
          />
          <image
            xlinkHref={
              mapProductLensTypeToScenario(props.lensType, props.productLensType1, props.fovZonesMode, props.sunMode)[4]
            }
            x={scenarioOffset[0]}
            y={scenarioOffset[1]}
            width={scenarioSize}
            height={scenarioSize}
            mask={`url(#${props.elementId}-fov-mask2)`}
            clipPath={fovClipPath}
          />
          <polyline className="fovLine2" points={fovLineRight2} visibility={drawFov2} clipPath={fovClipPath} />
          <polyline className="fovLine2" points={fovLineLeft2} visibility={drawFov2} clipPath={fovClipPath} />
          <polyline
            className={props.fovZonesMode === FovZonesMode.STANDARD ? "fovLine1" : "fovLine1White"}
            points={fovLineRight1}
            visibility={drawFov1}
            clipPath={fovClipPath}
          />
          <polyline
            className={props.fovZonesMode === FovZonesMode.STANDARD ? "fovLine1" : "fovLine1White"}
            points={fovLineLeft1}
            visibility={drawFov1}
            clipPath={fovClipPath}
          />
          <path
            transform={frameShapeTransformStr}
            visibility={frameShapeVisibility}
            stroke={frameColor}
            fill="none"
            d={frameShapePath}
          />
          <path
            transform={lensShapeInFrameTransformStr}
            visibility={frameShapeVisibility}
            stroke={frameColor}
            fill="none"
            d={lensShapePath}
          />
          <line
            className="dFdNLine"
            x1={dFLineCoords[0]}
            y1={dFLineCoords[1]}
            x2={dFLineCoords[2]}
            y2={dFLineCoords[3]}
            visibility={drawTunerIndicators}
          />
          <line
            className="dFdNLine"
            x1={dNLineCoords[0]}
            y1={dNLineCoords[1]}
            x2={dNLineCoords[2]}
            y2={dNLineCoords[3]}
            visibility={drawTunerIndicators}
          />
          <line
            className="centeringCross"
            x1={centeringCrossCoords[0]}
            y1={centeringCrossCoords[1]}
            x2={centeringCrossCoords[2]}
            y2={centeringCrossCoords[3]}
            visibility={drawTunerIndicators}
          />
          <line
            className="centeringCross"
            x1={centeringCrossCoords[4]}
            y1={centeringCrossCoords[5]}
            x2={centeringCrossCoords[6]}
            y2={centeringCrossCoords[7]}
            visibility={drawTunerIndicators}
          />
          <rect
            className="tunerTextBackground"
            x={dFTitleBackgroundRect[0]}
            y={dFTitleBackgroundRect[1]}
            width={dFTitleBackgroundRect[2]}
            height={dFTitleBackgroundRect[3]}
            visibility={drawTunerIndicators}
          />
          <rect
            className="tunerTextBackground"
            x={dFValBackgroundRect[0]}
            y={dFValBackgroundRect[1]}
            width={dFValBackgroundRect[2]}
            height={dFValBackgroundRect[3]}
            visibility={drawTunerIndicators}
          />
          <text
            x={dFTitleIndicatorCoords[0]}
            y={dFTitleIndicatorCoords[1]}
            className="tunerText"
            ref={dFTitleTextRef}
            visibility={drawTunerIndicators}
          >
            {dFTitleText}
          </text>
          <text
            x={dFValIndicatorCoords[0]}
            y={dFValIndicatorCoords[1]}
            className="tunerText"
            ref={dFValTextRef}
            visibility={drawTunerIndicators}
          >
            {dFValText}
          </text>
          <rect
            className="tunerTextBackground"
            x={dNTitleBackgroundRect[0]}
            y={dNTitleBackgroundRect[1]}
            width={dNTitleBackgroundRect[2]}
            height={dNTitleBackgroundRect[3]}
            visibility={drawTunerIndicators}
          />
          <rect
            className="tunerTextBackground"
            x={dNValBackgroundRect[0]}
            y={dNValBackgroundRect[1]}
            width={dNValBackgroundRect[2]}
            height={dNValBackgroundRect[3]}
            visibility={drawTunerIndicators}
          />
          <text
            x={dNTitleIndicatorCoords[0]}
            y={dNTitleIndicatorCoords[1]}
            className="tunerText"
            ref={dNTitleTextRef}
            visibility={drawTunerIndicators}
          >
            {dNTitleText}
          </text>
          <text
            x={dNValIndicatorCoords[0]}
            y={dNValIndicatorCoords[1]}
            className="tunerText"
            ref={dNValTextRef}
            visibility={drawTunerIndicators}
          >
            {dNValText}
          </text>
        </svg>
      </div>
    </>
  );
}
export { FovFrameShade, FovRenderingMode };
