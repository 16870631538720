import { ReactThreeFiber } from '@react-three/fiber';
import { Texture } from 'three';

import { shaderMaterial } from './shader-material';

/**
 * Simple Vertex shader:
 * Passes world position and texture coordinates
 */
const vertexShader = `
  varying vec2 vUv;

  void main() {
    vUv         = uv;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}`;

/**
 * Fragment shader for lens distortion:
 * Based on https://codepen.io/johanberonius/pen/RopjYW
 */
const fragmentShader = `
uniform sampler2D map;
uniform sampler2D mapBlur;
uniform float fovRadius;
uniform float fovYOffset;
uniform float distortionStrength;
uniform float plusMinusFactor;
varying vec2 vUv;

void main() {
  vec2 uvNormalized = vUv * 2.0 - 1.0;  // Change UV range from [0, 1] to [-1, 1]
  uvNormalized[1] -= fovYOffset / 4.0;
  float radius = length(uvNormalized);

  vec2 uvNormalized1 = vUv * 2.0 - 1.0;  // Change UV range from [0, 1] to [-1, 1]
  float radius1 = length(uvNormalized1);

  if(radius1 > 0.9) {
    discard;
  }

  if(radius < fovRadius) {
    gl_FragColor.rgba = texture2D(map, vUv).rgba;
  }
  else{
    float a = asin(radius - fovRadius);
    float z = (1.0 - cos(a)) * distortionStrength;
   
    vec2 uvDistorted = vUv + plusMinusFactor * uvNormalized * z;
    gl_FragColor.rgba = texture2D(mapBlur, uvDistorted).rgba;
  }
}`;

export const LensDistortionRoundMaterial = shaderMaterial(
  {
    map: new Texture(),
    mapBlur: new Texture(),
    fovRadius: 0,
    fovYOffset: 0,
    distortionStrength: 1.0,
    plusMinusFactor: 1.0,
  },
  vertexShader,
  fragmentShader
);

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      lensDistortionRoundMaterial: ReactThreeFiber.Node<
        typeof LensDistortionRoundMaterial &
          JSX.IntrinsicElements['shaderMaterial'],
        typeof LensDistortionRoundMaterial
      >;
    }
  }
}
