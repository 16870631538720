/**
 * Rendering modes supported by the field-of-view renderers
 */
export enum FovRenderingMode {
    FULL = 0,
    LENS_SHAPE = 1,
    FRAME_SHAPE = 2,
}
  
/**
 * Shade of the Frame outline
 */
export enum FovFrameShade {
    DARK = 0,
    LIGHT = 1,
}

/**
 * Size of the viewBox used for our SVG component (origin is always [0, 0]).
 * This is the same size as our scenario images' resolution
 */
export const svgViewBox = [1526, 1280];
export const svgViewBoxStr = `0 0 ${svgViewBox[0]} ${svgViewBox[1]}`;

/**
 * Original viewBox of the lens shape SVG is [683.99, 545.67].
 * Rescale using our current viewBox (this slightly streches the lens Shape)
 */
export const lensShapeScaleStr = `scale(${svgViewBox[0] / 683.99} ${svgViewBox[1] / 545.67})`;

/**
 * Original viewBox of the frame shape SVG is [835.238, 593.901].
 * Rescale using our current viewBox (apply horizontal scaling factor to width AND height to preserve frame shape's aspect ratio)
 */
export const frameShapeScaleStr = `scale(${svgViewBox[0] / 835.238} ${svgViewBox[0] / 835.238})`;
export const frameShapeYOffset = (svgViewBox[1] - (593.901 * svgViewBox[0]) / 835.238) / 2;
export const frameShapeTransformStr = `translate(0, ${frameShapeYOffset}) ${frameShapeScaleStr}`;

/**
 * When using Frame shape, the lens shape has to be resized to 80% of its original size, and also re-centered
 */
export const lensShapeInFrameScaleStr = `scale(${(0.8 * svgViewBox[0]) / 683.99} ${(0.8 * svgViewBox[1]) / 545.67})`;
export const lensShapeInFrameXOffset = (svgViewBox[0] - 0.82 * svgViewBox[0]) / 2; // NOTE: We use 0.82 instead of 0.8 to compensate for the A.R disparity between frame shape (undistorted) & lens shape (distorted)
export const lensShapeInFrameYOffset = (svgViewBox[1] - 0.8 * svgViewBox[1]) / 2;
export const lensShapeInFrameTransformStr = `translate(${lensShapeInFrameXOffset}, ${lensShapeInFrameYOffset}) ${lensShapeInFrameScaleStr}`;

/**
 * Lens shape as SVG path
 */
export const lensShapePath="M515,15.35A1173.75,1173.75,0,0,0,333.45,1,834.84,834.84,0,0,0,6.57,65.21S1,142.12,1,194.41a412.34,412.34,0,0,0,7.07,73.78c11.42,61,48.53,139.28,88.48,181.35,40.89,43.1,194.83,127.46,351.78,82.12S649.81,347.94,663.16,293.13c9.13-37.44,16.58-70.82,17.81-98.23,3.38-74.73,6.41-122.38-25.37-141.3S580.37,24.52,515,15.35Z"
 
/**
 * Frame shape as SVG path
 */
export const frameShapePath="M.5,196.407h0V90.51S154.043.5,399.137.5c233.671,0,257.62,45.38,414.715,49.589a20.466,20.466,0,0,1,19.535,24.226l-19.735,106.4-.164.632c-45.772,16.523-45.59,92.317-66.244,159.525-16.965,55.221-60.052,192.135-227.38,238.271s-326.083-39.666-368.652-82.235C68.245,413.941,43.392,295.561,40.962,267.428,39.079,245.691,26.6,209.75.5,196.407Z"