import "./App.css";
import { FieldOfViewProgressiveForm } from "./components/field-of-view-forms/field-of-view-progressive-form";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import { FieldOfViewSingleVisionForm } from "./components/field-of-view-forms/field-of-view-singleVision-form";

function App() {
  const useStyles = makeStyles((theme: any) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));
  const classes = useStyles();

  const [newSVFOV, setNewSVFOV] = useState<boolean>(false);

  return (
    <div className="App">
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setNewSVFOV(!newSVFOV)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {newSVFOV ? "FoV - Single Vision" : "FoV - Progressive & Near vision"}
          </Typography>
        </Toolbar>
      </AppBar>
      {newSVFOV ? <FieldOfViewSingleVisionForm /> : <FieldOfViewProgressiveForm />}
    </div>
  );
}

export default App;
